import * as React from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import ProductTemplate from '../../templates/product/product';
import { useExtractImagesFromGqlData } from '../../hooks/useExtractImagesFromGqlData';

const CrateBoxesPage = ({ location }: PageProps) => {
  const translationsKey = 'crate_boxes';
  const graphqlData = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: {
          absolutePath: { regex: "/categories/crate-boxes/" }
          sourceInstanceName: { eq: "images" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                placeholder: TRACED_SVG
                width: 540
              )
            }
          }
        }
      }
    }
  `);

  const images = useExtractImagesFromGqlData(graphqlData);

  return (
    <ProductTemplate
      location={location}
      translationsKey={translationsKey}
      images={images}
    />
  );
};

export default CrateBoxesPage;
